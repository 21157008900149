import { useRef, useState } from 'react'
import { InView } from 'react-intersection-observer'
import cn from 'classnames'
import Image from 'next/image'
// Components
import SearchBar from '@/components/search/Bar'
import styles from './Hero.module.scss'
import { Trustpilot } from '../trustpilot/trustpilot-landing'

type HeroProps = {
  // eslint-disable-next-line no-unused-vars
  setNewRoute: (key: string, value: string) => void
  isMobile: boolean
  isTablet: boolean
  isDiscountBannerClosed: boolean
}

export default function Hero({
  setNewRoute,
  isMobile,
  isTablet,
  isDiscountBannerClosed,
}: HeroProps) {
  /* SEARCH BAR BEHAVIOR */
  const [pinSearchBar, setPinSearchBar] = useState(false)
  const scrollRef = useRef(null)

  return (
    <InView className={styles.hero} onChange={(b) => setPinSearchBar(!b)}>
      <div className={styles.top}>
        <div className={styles.xMas}>
          <div className={styles.imageContainer}>
            <div className={cn(styles.image, styles.desktop)}>
              <Image
                width={900}
                height={360}
                src="/home/peekaboo_puppies-spring.webp"
                alt="Australian Shepherd, Pomeranian, Golden Retriever, English Bulldog, and Pointer puppies sitting side by side with flowers"
                style={{ objectFit: 'contain' }}
                quality={100}
                priority
              />
            </div>
            <div className={cn(styles.image, styles.mobile)}>
              <Image
                src="/home/peekaboo_puppies-spring-mobile.png"
                alt="Australian Shepherd, Pomeranian, Golden Retriever, English Bulldog, and Pointer puppies sitting side by side with flowers"
                style={{ width: '100%', height: 'auto' }}
                width={390}
                height={210}
                priority
                quality={100}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.bottom}>
        <h1>Find Your Perfect Puppy with Confidence</h1>
        <h2>
          Certified Breeders, Health Guarantees, and a Seamless Journey Trusted
          Nationwide
        </h2>

        <div
          ref={scrollRef}
          className={cn(
            styles.searchBar,
            pinSearchBar ? styles.pinned : styles.unpinned,
          )}
        >
          <SearchBar
            setNewRoute={setNewRoute}
            scrollRef={scrollRef}
            pinSearchBar={pinSearchBar}
            isTablet={isTablet}
            // Only needed for mobile
            isMobile={isMobile}
            isDiscountBannerClosed={isDiscountBannerClosed}
            isHome
            selectedZipcode=""
            selectedBreed={undefined}
            setSelectedBreed={undefined}
            selectedCategory={undefined}
            setShowNotificationForm={undefined}
          />
        </div>
        <div className={styles.trustpilot}>
          <Trustpilot />
        </div>
      </div>
    </InView>
  )
}
