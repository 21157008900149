import useMediaQuery from '@/utils/publicApi'
import styles from './trustpilot.module.scss'

export const Trustpilot = () => {
  const isTablet = useMediaQuery('(max-width:1270px)')
  return (
    <div className={styles.trustpilot}>
      <h2 className={styles.title}>Excellent</h2>
      <a href="https://www.trustpilot.com/review/www.mawoopets.com?utm_medium=trustbox&utm_source=Carousel">
        <div
          className={styles.starts}
          role="img"
          aria-label="Stars Trustpilot"
        />
      </a>
      {!isTablet && (
        <p className={styles.text}>
          4.8 <span className={styles.rest}> out of 5</span>
        </p>
      )}
      <a href="https://www.trustpilot.com/review/www.mawoopets.com?utm_medium=trustbox&utm_source=Carousel">
        <div className={styles.logo} role="img" aria-label="Brand Trustpilot" />
      </a>
    </div>
  )
}
